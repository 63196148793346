import React from 'react'
import { useHistory } from 'react-router-dom'

import { getLinkToContactUs } from '../../services/router_dom/router_dom_utils'

export const ContactUsButton = ({ id, section = 'book-demo', children }) => {
  const history = useHistory()
  const goToContactUs = () => {
    history.push(getLinkToContactUs({ resource_id: section }))
  }
  return (
    <button
      id={id}
      className='button request-demo--button'
      onClick={goToContactUs}
    >
      <a>
        {children}
      </a>
    </button>
  )
}
