import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} })

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        // palette values for light mode
        primary: {
          main: '#652F94'
        },
        secondary: {
          main: '#8898aa'
        },
        background: {
          default: '#ffffff',
          paper: '#eef5f9'
        },
        text: {
          primary: '#060019e6',
          secondary: '#8898aa'
        },
        divider: '#060019e6',
        card_background: {
          main: '#ffffff',
          light: '#ffffff',
          dark: '#272b34',
          contrastText: '#C8C8C8'
        }
      }
      : {
        // palette values for dark mode
        primary: {
          main: '#3cb371'
        },
        secondary: {
          main: '#8898aa'
        },
        background: {
          default: '#323743',
          paper: '#212529'
        },
        text: {
          primary: '#ffffff',
          secondary: '#C8C8C8'
        },
        divider: '#ffffff',
        card_background: {
          main: '#272b34',
          light: '#ffffff',
          dark: '#272b34',
          contrastText: '#C8C8C8'
        }
      }),
    green: {
      light: '#3cb371',
      main: '#3cb371',
      dark: '#3cb371',
      contrastText: '#060019e6'
    },
    yellow: {
      light: '#FFF74A',
      main: '#FFF74A',
      dark: '#FFF74A',
      contrastText: '#060019e6'
    },
    black: {
      main: '#060019e6',
      light: '#060019e6',
      dark: '#060019e6',
      contrastText: '#8898aa'
    },
    white: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#ffffff',
      contrastText: '#C8C8C8'
    },
    custom_colors: {
      primary: '#652F94',
      secondary: '#8898aa',
      success: '#21944B',
      info: '#4F5A97',
      warning: '#DB5D43',
      danger: '#D6360B',
      light: '#f6f9fc',
      dark: '#32325d',
      cyan: '#4fc3f7',
      orange: '#fb6340',
      purple: '#5e72e4',
      inverse: '#32325d',
      black: '#060019e6',
      main: '#2B1757',
      green: '#3cb371'
    }
  },
  typography: {
    fontFamily: 'Ubuntu, sans-serif'
  }
})

export const MuiThemeProvider = ({ children }) => {
  const prefers_dark_mode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = React.useState('dark')
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode])
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const next_theme = prevMode === 'light' ? 'dark' : 'light'
          const htmlElement = document.documentElement
          htmlElement.setAttribute('data-theme', next_theme)
          return next_theme
        }
        )
      }
    }),
    []
  )
  React.useEffect(() => {
    if (prefers_dark_mode && theme.palette.mode === 'light') {
      colorMode.toggleColorMode()
    } else if (!prefers_dark_mode && theme.palette.mode === 'dark') {
      colorMode.toggleColorMode()
    }
  }, [prefers_dark_mode, colorMode])
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}
