import React from 'react'
import { Header } from './layout_components/header/header'
import Footer from './layout_components/footer/footer'
import GDPRDialog from './layout_components/gdpr_dialog'
import { WhatsAppButton } from './layout_components/customer_support/whats_app_button'

export const Layout = ({ children }) => {
  return (
    <div id='layout'>
      <Header/>
      {children}
      <Footer/>
      <WhatsAppButton/>
      <GDPRDialog/>
    </div>
  )
}
