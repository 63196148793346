import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import i18n from '../../../i18n'
import footer_logo from '../../../assets/images/logos/ecclesia_logo_horizontal_big.png'
import footer_logo_mobile from '../../../assets/images/logos/ecclesia_logo_horizontal_small.png'
import { external_links } from '../../../assets/links/external_links'

import { Icon } from '../../../components/icons/icon'
import TopicListItem from './footer_components/topic_list_item'
import { ContactUsButton } from '../../../components/buttons/contact_us_button'

library.add(faFacebook, faInstagram, faEnvelope)

const icons_list = [
  { type: faEnvelope, link: external_links.ecclesia.email.link },
  { type: faFacebook, link: external_links.ecclesia.facebook.link },
  { type: faInstagram, link: external_links.ecclesia.instagram.link },
  { type: faWhatsapp, link: external_links.ecclesia.whats_app.link }
]

const Footer = () => {
  const { help, about_us, contact_us } = i18n.t(
    'footer',
    { returnObjects: true }
  )
  return (
    <footer id={'footer--container'}>
      <div className="footer">
        <h1>{i18n.t('footer.title')}</h1>
        <div className="footer__request-demo--container">
          <ContactUsButton id={'footer__request-demo--button'}>
            {i18n.t('buttons.request_demo.content')}
          </ContactUsButton>
        </div>
        <div className="footer__items">
          <div>
            <p className='footer___topic-title'>{help.title}</p>
            {help.items.map((item, key) => (
              <TopicListItem
                key={key}
                url={item.url}
                section={item.section}
              >
                {item.name}
              </TopicListItem>
            )
            )}
            <TopicListItem
              url={external_links.ecclesia.support.link}
              external_link={true}
            >
              {help.support}
            </TopicListItem>
          </div>
          <div>
            <p className='footer___topic-title'>{about_us.title}</p>
            {about_us.items.map((item, key) => (
              <TopicListItem
                key={key}
                url={item.url}
                section={item.section}
              >
                {item.name}
              </TopicListItem>
            )
            )}
          </div>
          <div>
            <p className='footer___topic-title'>{contact_us.title}</p>
            {contact_us.items.map((item, key) => (
              <TopicListItem
                key={key}
                url={item.url}
                section={item.section}
              >
                {item.name}
              </TopicListItem>
            )
            )}
            <TopicListItem
              url={external_links.ecclesia.telephone.link}
              external_link={true}
            >
              {external_links.ecclesia.telephone.name}
            </TopicListItem>
          </div>
        </div>
        <div className="footer__logo-container">
          <img
            className="footer_logo not_mobile"
            src={footer_logo}
            alt="Ecclesia footer logo"
            title="Ecclesia footer logo"
          />
          <img
            className="footer_logo mobile"
            src={footer_logo_mobile}
            alt="Ecclesia footer_logo mobile"
            title="Ecclesia footer logo mobile"
          />
        </div>
        <div className="footer__icons-container">
          <div>
            {icons_list.map((icon, key) => (
              <a
                href={icon.link}
                target="_blank"
                rel="noreferrer"
                key={`footer-icon-${key}`}
              >
                <Icon icon={icon.type} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
