export const external_links = {
  ecclesia: {
    telephone: {
      link: 'tel:+573133723863',
      name: '+57 313 372 3863'
    },
    email: {
      link: 'mailto:contact@ecclesia.com.co',
      name: 'e-mail'
    },
    facebook: {
      link: 'https://www.facebook.com/ecclesia.is',
      name: 'Facebook'
    },
    instagram: {
      link: 'https://www.instagram.com/ecclesia.is',
      name: 'Instagram'
    },
    whats_app: {
      link: 'https://wa.me/+573133723863',
      name: 'WhatsApp'
    },
    support: {
      link: 'https://wa.me/message/36WYU6Z74TJJJ1',
      name: 'Technical Support'
    }
  }
}
