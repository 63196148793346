import React from 'react'
import { useTheme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'

import { ColorModeContext } from '../../../services/mui/mui_utils'

export const ThemeModeToggle = ({ full_width }) => {
  const theme = useTheme()
  const colorMode = React.useContext(ColorModeContext)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'text.primary',
        width: full_width ? '100%' : 'auto'
      }}
    >
      <IconButton sx={{ ml: 1, mr: 1, width: full_width ? '100%' : 'fit-content' }} onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
      </IconButton>
    </Box>
  )
}
