import i18n from '../../i18n'

const getBrowserLanguage = () => {
  try {
    if (navigator.languages !== undefined && navigator.languages !== null) {
      return navigator.languages[0]
    } else if (navigator.language) {
      return navigator.language
    }
  } catch (e) {
    console.error(e)
    return null
  }
}

export const getCurrentLanguage = () => {
  try {
    const base_path = window.location.pathname.split('/')[1]
    if (['en', 'es', 'pe'].includes(base_path)) {
      return base_path
    } else {
      return null
    }
  } catch (e) {
    console.error(e)
    return null
  }
}

export const setupLanguage = () => {
  const current_language = getCurrentLanguage()
  let language_identifier
  if (current_language) {
    language_identifier = current_language
  } else {
    if (localStorage.getItem('language') !== null) {
      language_identifier = localStorage.getItem('language')
    } else {
      language_identifier = getBrowserLanguage().substring(0, 2)
    }
  }
  if (!['en', 'es', 'pe'].includes(language_identifier)) {
    language_identifier = 'en'
  }
  return language_identifier
}

export const changeLanguage = (lang) => {
  const current_language = getCurrentLanguage()
  if (current_language && (lang !== current_language)) {
    localStorage.setItem('language', lang)
    window.location.href = window.location.pathname.replace(`/${current_language}`, `/${lang}`)
  }
}

export const getLanguageIndicator = () => {
  try {
    const current_language = getCurrentLanguage()
    if (current_language && typeof current_language === 'string') {
      return current_language.toUpperCase()
    } else {
      return 'EN'
    }
  } catch (e) {
    console.error(e)
    return 'EN'
  }
}

export const setupLanguageTranslator = async () => {
  try {
    const current_language = getCurrentLanguage()
    if (current_language) {
      await i18n.changeLanguage(current_language)
    }
  } catch (e) {
    console.error(e)
  }
}
