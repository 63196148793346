import React from 'react'
import { NavLink } from 'react-router-dom'

import i18n from '../../i18n'
import slack_notifier from '../../services/slack_notifier/slack_notifier'
import { getLinkToPath } from '../../services/router_dom/router_dom_utils'

const GDPRDialog = (props) => {
  const [open, setOpen] = React.useState(localStorage.getItem('consentMode') === null)
  const handleClose = () => { setOpen(false) }

  if (!open) return null
  return (
    <div className={'gdpr_consent_dialog'}>
      <div className={'gdpr_consent_content'}>
        <div className={'gdpr_consent_message'}>
          <span>
            {i18n.t('gdpr_consent_dialog.cookies_message')}
            <NavLink
              to={getLinkToPath('policies')}>
              {i18n.t('gdpr_consent_dialog.privacy_policy')}
            </NavLink>
            {i18n.t('gdpr_consent_dialog.disagree_message')}
            <a className={'gdpr_reject_link'} onClick={ handleClose }>
              {i18n.t('gdpr_consent_dialog.here')}
            </a>
          </span>
        </div>
        <div className={'gdpr_consent_buttons_container'}>
          <button className={'gdpr_consent_button'}
            onClick={ () => {
              window.consentGranted()
              slack_notifier.notifyGdprConsent().then(() => handleClose()).catch(e => console.error(e))
            }}
            autoFocus
          >
            {i18n.t('gdpr_consent_dialog.accept_all_cookies')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default GDPRDialog
