import { getCurrentLanguage } from '../language_manager/language_manager'

export const getLinkToHome = () => {
  const language = getCurrentLanguage()
  return {
    pathname: `/${language}`
  }
}
export const getLinkToGlossaryItem = (glossary_id) => {
  const language = getCurrentLanguage()
  return {
    pathname: `/${language}/help/resources`,
    state: {
      to_glossary: true,
      resource_id: glossary_id
    }
  }
}

export const getLinkToBlogEntry = (blog_entry_name) => {
  const language = getCurrentLanguage()
  return {
    pathname: `/${language}/blog/${blog_entry_name}`
  }
}

export const getLinkTo = ({ pathname }) => {
  const language = getCurrentLanguage()
  return {
    pathname: `/${language}/${pathname}`
  }
}

export const getLinkToPath = (pathname) => {
  return getLinkTo({ pathname: pathname })
}

export const getLinkToContactUs = ({ resource_id }) => {
  return {
    pathname: getLinkToPath('contact-us').pathname,
    state: {
      resource_id: resource_id
    }
  }
}
