import React from 'react'
import { useHistory } from 'react-router-dom'

const TopicListItem = ({ url, section, external_link, children }) => {
  const history = useHistory()
  const handleOnClick = () => {
    if (external_link) {
      window.open(url, '_blank')
    } else {
      history.push({
        pathname: url,
        state: {
          resource_id: section
        }
      })
    }
  }
  return <p className="footer_topic_item" onClick={handleOnClick}>{children}</p>
}

export default TopicListItem
