import React, { Suspense, lazy, useState, useCallback, useEffect } from 'react'
import { Switch, Route, Redirect, withRouter, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Layout } from './layouts/layout'
import { Loading } from './views/loading/loading'
import './assets/scss/style.scss'

import { setupLanguage, setupLanguageTranslator } from './services/language_manager/language_manager'
import { MuiThemeProvider } from './services/mui/mui_utils'

const Pricing = lazy(() => import('./views/pricing/pricing'))
const UseCases = lazy(() => import('./views/use_cases/use_cases'))
const Home = lazy(() => import('./views/home/home'))
const BlogIndex = lazy(() => import('./views/blog/blog_index'))
const LawsColombiaBlogEntry = lazy(() => import('./views/blog/blog_entries/blog_entries_es/laws_colombia_blog_entry_es'))
const ParticipationEcclesiaBlogEntry = lazy(() => import('./views/blog/blog_entries/blog_entries_es/participation_ecclesia_blog_entry_es'))
const ViableSystemsBlogEntry = lazy(() => import('./views/blog/blog_entries/blog_entries_es/viable_systems_blog_entry_es'))
const ContactUs = lazy(() => import('./views/contact_us/contact_us'))
const Resources = lazy(() => import('./views/resources/resources'))
const Policies = lazy(() => import('./views/policies/policies'))
const FrequentlyAskedQuestions = lazy(() => import('./views/faq/faq'))
const NotFound = lazy(() => import('./views/not_found/not_found'))
const HelpIndex = lazy(() => import('./views/help_index/help_index'))
const VideoTutorials = lazy(() => import('./views/video_tutorials/video_tutorials'))
const Manuals = lazy(() => import('./views/manuals/manuals'))
const Team = lazy(() => import('./views/team/team'))
const TermsOfUseAndConditions = lazy(() => import('./views/terms_of_use_and_conditions/terms_of_use_and_conditions'))
const Assemblies = lazy(() => import('./views/home/assemblies'))
const Voting = lazy(() => import('./views/home/voting'))

function App () {
  const [language] = useState(setupLanguage())
  const [pageTitle, setPageTitle] = useState('Ecclesia | Home')
  const [pageDescription, setPageDescription] = useState('Gestiona tus asambleas virtuales de manera personalizada, con votaciones electrónicas, actas automatizadas, quórum y los registros que necesitas.')
  const changePageTitle = useCallback(
    (view_name) => {
      setPageTitle(`Ecclesia | ${view_name}`)
    }, [setPageTitle]
  )
  const changePageDescription = useCallback(
    description => { setPageDescription(description) }, [setPageDescription]
  )
  const BASE_PATH = `/${language}`
  const location = useLocation()
  const to_glossary = location && location.state && location.state.to_glossary
  const resource_id = location && location.state && location.state.resource_id
  useEffect(() => {
    try {
      if (!to_glossary) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    } catch (e) {
      console.error(e)
    }
  })
  useEffect(async () => {
    await setupLanguageTranslator()
  }, [language])

  return (
    <Suspense fallback={<Loading/>}>
      <MuiThemeProvider>
        <Layout>
          <Helmet>
            <title>{pageTitle}</title>
            <meta charSet="utf-8" name="description" content={pageDescription}/>
          </Helmet>
          <Route path={'/*'}>
            <Switch>
              <Route exact path={'/'}>
                <Redirect to={`/${language}`}/>
              </Route>
              <Route exact path={'/home'}>
                <Redirect to={`/${language}`}/>
              </Route>
              <Route exact path={'/inicio'}>
                <Redirect to={`/${language}`}/>
              </Route>
              <Route exact path={'/en/assemblies'}>
                <Assemblies changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route exact path={'/es/asambleas'}>
                <Assemblies changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route exact path={'/en/asambleas'}>
                <Redirect to={'/en/assemblies'}/>
              </Route>
              <Route exact path={'/es/assemblies'}>
                <Redirect to={'/es/asambleas'}/>
              </Route>
              <Route exact path={'/es/votaciones'}>
                <Voting changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route exact path={'/en/voting'}>
                <Voting changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route exact path={'/en/votaciones'}>
                <Redirect to={'/en/voting'}/>
              </Route>
              <Route exact path={'/es/voting'}>
                <Redirect to={'/es/votaciones'}/>
              </Route>
              <Route exact path={'/pe/assemblies'}>
                <Redirect to={'/pe/asambleas'}/>
              </Route>
              <Route exact path={'/pe/asambleas'}>
                <Assemblies changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route exact path={BASE_PATH}>
                <Home changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route path={`${BASE_PATH}/pricing`}>
                <Pricing changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route exact path={`${BASE_PATH}/blog/index`}>
                <BlogIndex changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route exact path={'/es/blog/leyes-asambleas-virtuales-propiedad-horizontal-colombia'}>
                <LawsColombiaBlogEntry changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route exact path={'/es/blog/como-participar-en-asambleas-virtuales'}>
                <ParticipationEcclesiaBlogEntry changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route exact path={'/es/blog/sociedad-como-sistema-viable'}>
                <ViableSystemsBlogEntry changePageTitle={changePageTitle} changePageDescription={changePageDescription} />
              </Route>
              <Route path={`${BASE_PATH}/online-discussion-tool`} exact>
                <UseCases changePageTitle={changePageTitle} market_group={'education'} />
              </Route>
              <Route path={`${BASE_PATH}/online-decision-making-meeting`} exact>
                <UseCases changePageTitle={changePageTitle} market_group={'business'} />
              </Route>
              <Route path={`${BASE_PATH}/virtual-owners-association-meetings`} exact>
                <UseCases changePageTitle={changePageTitle} market_group={'property'} />
              </Route>
              <Route path={`${BASE_PATH}/help`} exact>
                <HelpIndex changePageTitle={changePageTitle} />
              </Route>
              <Route path={`${BASE_PATH}/help/faq`} exact>
                <FrequentlyAskedQuestions changePageTitle={changePageTitle} />
              </Route>
              <Route path={`${BASE_PATH}/help/video-tutorials`} exact>
                <VideoTutorials changePageTitle={changePageTitle} />
              </Route>
              <Route path={`${BASE_PATH}/help/manuals`} exact>
                <Manuals changePageTitle={changePageTitle} />
              </Route>
              <Route path={`${BASE_PATH}/help/resources`} exact>
                <Resources changePageTitle={changePageTitle} resource_id={resource_id} />
              </Route>
              <Route path={`${BASE_PATH}/about-us/team`}>
                <Team changePageTitle={changePageTitle} />
              </Route>
              <Route path={`${BASE_PATH}/contact-us`}>
                <ContactUs changePageTitle={changePageTitle} />
              </Route>
              <Route path={`${BASE_PATH}/policies`}>
                <Policies changePageTitle={changePageTitle} />
              </Route>
              <Route path={`${BASE_PATH}/terms-of-use-and-conditions`}>
                <TermsOfUseAndConditions changePageTitle={changePageTitle} />
              </Route>
              <Route path={`${BASE_PATH}/not-found`} exact>
                <NotFound/>
              </Route>
              <Route>
                <Redirect to={`${BASE_PATH}/not-found`}/>
              </Route>
            </Switch>
          </Route>
        </Layout>
      </MuiThemeProvider>
    </Suspense>
  )
}

export default withRouter(App)
