import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from './locales/en/translation.json'
import translationES from './locales/es/translation.json'
import translationPE from './locales/pe/translation.json'

const resources = {
  en: { translation: translationEN },
  es: { translation: translationES },
  pe: { translation: translationPE }
}

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: ['en', 'es', 'pe'],
    interpolation: { escapeValue: false }
  })

export default i18next
