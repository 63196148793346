const axios = require('axios')

class SlackNotifier {
  constructor () {
    this.axios_instance = axios.create({ baseURL: process.env.REACT_APP_SLACK_WEBHOOK_URL })
    this.gdpr_consent_info_path = process.env.REACT_APP_SLACK_GDPR_CONSENT_INFO
  }

  async sendGenericNotification (path, data) {
    await this.axios_instance.post(path, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(res => {}).catch(error => { console.error(error) })
  }

  async notifyGdprConsent () {
    const blocks = [
      {
        type: 'header',
        text: { type: 'plain_text', text: 'Someone consent GDPR', emoji: true }
      },
      {
        type: 'divider'
      }
    ]

    this.sendGenericNotification(this.gdpr_consent_info_path, { blocks: blocks })
  }
}

const slack_notifier = new SlackNotifier()
export default slack_notifier
