import React from 'react'
import { Header } from '../../layouts/layout_components/header/header'

export const Loading = () => {
  return (
    <div className='loading-view'>
      <Header/>
    </div>
  )
}
