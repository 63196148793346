import React, { useState, useEffect } from 'react'
import { NavLink, Link, useHistory } from 'react-router-dom'

import i18n from '../../../i18n'
import { getLinkToHome, getLinkToPath, getLinkToContactUs } from '../../../services/router_dom/router_dom_utils'
import { getCurrentLanguage, changeLanguage, getLanguageIndicator } from '../../../services/language_manager/language_manager'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Paper, Menu, MenuItem } from '@material-ui/core'

import small_logo from '../../../assets/images/logos/ecclesia_logo_horizontal_small.png'
import { ThemeModeToggle } from './theme_mode_toggle'
import { MobileMenu } from './mobile_menu'

library.add(faBars)

export const Header = () => {
  const history = useHistory()
  const language = getCurrentLanguage()
  // const [use_cases_menu_open, setUseCasesMenuOpen] = useState(null)
  // const openUseCasesMenu = () => setUseCasesMenuOpen(true)
  // const closeUseCasesMenu = () => setUseCasesMenuOpen(false)
  const [help_menu_open, setHelpMenuOpen] = useState(null)
  const openHelpMenu = () => setHelpMenuOpen(true)
  const closeHelpMenu = () => setHelpMenuOpen(false)
  const [about_us_menu_open, setAboutUsMenuOpen] = useState(null)
  const openAboutUsMenu = () => setAboutUsMenuOpen(true)
  const closeAboutUsMenu = () => setAboutUsMenuOpen(false)
  const [language_anchor_element, setLanguageAnchorElement] = useState(null)
  const closeLanguageMenu = () => setLanguageAnchorElement(null)
  const path = window.location.pathname
  const current_route = path.substring(path.lastIndexOf('/') + 1)
  // const on_use_cases = ['online-discussion-tool', 'online-decision-making-meeting', 'virtual-owners-association-meetings'].includes(current_route)
  const on_help = ['faq', 'resources'].includes(current_route)
  const on_about_us = ['team', 'policies', 'terms-of-use-and-conditions'].includes(current_route)
  const toggleLanguage = () => {
    if (language === 'es') changeLanguage('en')
    else if (language === 'en') changeLanguage('es')
  }
  // const openUseCaseProperty = () => {
  //   history.push(getLinkToPath('virtual-owners-association-meetings'))
  // }
  const openHelpView = () => {
    history.push(getLinkToPath('help/faq'))
  }
  const openAboutUsView = () => {
    history.push(getLinkToPath('about-us/team'))
  }
  const handleIntersect = entries => {
    entries.forEach((entry) => {
      try {
        if (entry) {
          if (entry.isIntersecting) {
            const header = document.getElementById('layout__header--top-menu')
            header.classList.remove('header__translucent-background')
          } else {
            const header = document.getElementById('layout__header--top-menu')
            header.classList.add('header__translucent-background')
          }
        }
      } catch (e) {
        console.error(e)
      }
    })
  }
  const createIntersectionObserver = () => {
    try {
      if (
        'IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype
      ) {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0
        }
        const observer = new IntersectionObserver(handleIntersect, options)
        const target = document.getElementById('layout__header-intersection--target')
        if (target) {
          observer.observe(target)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(createIntersectionObserver, [])

  const header = i18n.t('header', { returnObjects: true })
  const {
    pricing, blog,
    help, faq, resources,
    about_us, contact_us, login,
    team, policies, terms_of_use_and_conditions
  } = header
  return (
    <React.Fragment>
      <header
        id='layout__header--top-menu'
      >
        <Link
          to={getLinkToHome}
        >
          <img
            id='header__ecclesia-logo--image'
            src={small_logo}
            alt="Ecclesia" title="Ecclesia Header Logo" />
        </Link>
        <nav className={'header__desktop-nav-bar'}>
          <NavLink
            to={getLinkToPath('pricing')}
            className={'nav__item'}
          >
            {pricing}
          </NavLink>
          <NavLink
            to={getLinkToPath('blog/index')}
            className={'nav__item'}
          >
            {blog}
          </NavLink>
          {/* <div */}
          {/*  className={'nav__menu--container'} */}
          {/*  onMouseOver={openUseCasesMenu} */}
          {/*  onMouseLeave={closeUseCasesMenu} */}
          {/* > */}
          {/*  <a */}
          {/*    className={'nav__item' + (on_use_cases ? ' active' : '') } */}
          {/*    onClick={openUseCaseProperty} */}
          {/*  > */}
          {/*    {use_cases} */}
          {/*  </a> */}
          {/*  { */}
          {/*    use_cases_menu_open && */}
          {/*    <div */}
          {/*      id={'nav__use-case--menu'} */}
          {/*      className={'nav__menu'} */}
          {/*    > */}
          {/*      <Paper */}
          {/*        className={'nav__menu--paper'} */}
          {/*        elevation={4} */}
          {/*      > */}
          {/*        <MenuItem className={'nav__menu--item'}> */}
          {/*          <NavLink */}
          {/*            to={getLinkToPath('online-discussion-tool')} */}
          {/*            className={'nav__menu--link'} */}
          {/*          > */}
          {/*            {education} */}
          {/*          </NavLink> */}
          {/*        </MenuItem> */}
          {/*        <MenuItem className={'nav__menu--item'}> */}
          {/*          <NavLink */}
          {/*            to={getLinkToPath('online-decision-making-meeting')} */}
          {/*            className={'nav__menu--link'}> */}
          {/*            {business} */}
          {/*          </NavLink> */}
          {/*        </MenuItem> */}
          {/*        <MenuItem className={'nav__menu--item'}> */}
          {/*          <NavLink */}
          {/*            to={getLinkToPath('virtual-owners-association-meetings')} */}
          {/*            className={'nav__menu--link'}> */}
          {/*            {property} */}
          {/*          </NavLink> */}
          {/*        </MenuItem> */}
          {/*      </Paper> */}
          {/*    </div> */}
          {/*  } */}
          {/* </div> */}
          <div
            className={'nav__menu--container'}
            onMouseOver={openHelpMenu}
            onMouseLeave={closeHelpMenu}
          >
            <a
              className={'nav__item' + (on_help ? ' active' : '') }
              onClick={openHelpView}
            >
              {help}
            </a>
            {
              help_menu_open &&
              <div
                id={'nav__help--menu'}
                className={'nav__menu'}
              >
                <Paper
                  className={'nav__menu--paper'}
                  elevation={4}>
                  <MenuItem className={'nav__menu--item'}>
                    <NavLink
                      to={getLinkToPath('help/faq')}
                      className={'nav__menu--link'}>
                      {faq}
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={'nav__menu--item'}>
                    <NavLink
                      to={getLinkToPath('help/resources')}
                      className={'nav__menu--link'}
                    >
                      {resources}
                    </NavLink>
                  </MenuItem>
                </Paper>
              </div>
            }
          </div>
          <div
            className={'nav__menu--container'}
            onMouseOver={openAboutUsMenu}
            onMouseLeave={closeAboutUsMenu}
          >
            <a
              className={'nav__item' + (on_about_us ? ' active' : '') }
              onClick={openAboutUsView}
            >
              {about_us}
            </a>
            {
              about_us_menu_open &&
              <div
                id={'nav__help--menu'}
                className={'nav__menu'}
              >
                <Paper
                  className={'nav__menu--paper'}
                  elevation={4}>
                  <MenuItem className={'nav__menu--item'}>
                    <NavLink
                      to={getLinkToPath('about-us/team')}
                      className={'nav__menu--link'}>
                      {team}
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={'nav__menu--item'}>
                    <NavLink
                      to={getLinkToPath('policies')}
                      className={'nav__menu--link'}>
                      {policies}
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={'nav__menu--item'}>
                    <NavLink
                      to={getLinkToPath('terms-of-use-and-conditions')}
                      className={'nav__menu--link'}>
                      {terms_of_use_and_conditions}
                    </NavLink>
                  </MenuItem>
                </Paper>
              </div>
            }
          </div>
          <NavLink
            to={getLinkToContactUs({ resource_id: 'book-demo' })}
            id='nav__contact-us--button'
            className='nav__item button'
          >
            {contact_us}
          </NavLink>
          <a
            id={'nav__login--button'}
            className={'nav__item'}
            href="https://app.ecclesia.com.co/dashboard"
            target="_blank" rel="noreferrer">
            {login}
          </a>
          <a
            id={'nav__language--button'}
            className={'nav__item'}
            onClick={(event) => { setLanguageAnchorElement(event.currentTarget) }}>
            { getLanguageIndicator() }
          </a>
          <Menu
            className={'nav__menu nav__language-options--menu'}
            anchorEl={language_anchor_element}
            open={Boolean(language_anchor_element)}
            keepMounted
            onClose={closeLanguageMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            elevation={1}
          >
            <MenuItem
              className={'nav__language-menu--item'}
              onClick={toggleLanguage}
            >
              { language === 'en' && 'ES' }
              { language === 'es' && 'EN' }
            </MenuItem>
          </Menu>
          <ThemeModeToggle/>
        </nav>
        <nav className={'header__mobile-nav-bar'}>
          <a
            id={'mobile-nav-bar__login-button'}
            href="https://app.ecclesia.com.co/dashboard"
            target="_blank" rel="noreferrer">
            {login}
          </a>
          <MobileMenu
            anchor_element_id={'layout__header--top-menu'}
          />
        </nav>
      </header>
      <div id={'layout__header-intersection--target'}/>
    </React.Fragment>
  )
}
