import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Menu, MenuItem } from '@material-ui/core'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'

import i18n from '../../../i18n'
import { getLinkToPath } from '../../../services/router_dom/router_dom_utils'
import { getCurrentLanguage, changeLanguage } from '../../../services/language_manager/language_manager'

import { ThemeModeToggle } from './theme_mode_toggle'

export const MobileMenu = ({ anchor_element_id }) => {
  const [anchor_element, setAnchorElement] = useState(null)
  const [secondary_menu, setSecondaryMenu] = React.useState(null)
  const closeMenu = () => setAnchorElement(null)
  const openMobileMenu = () => {
    setSecondaryMenu(null)
    setAnchorElement(document.getElementById(anchor_element_id))
  }
  const goToMainMenu = () => {
    setSecondaryMenu(null)
  }
  const language = getCurrentLanguage()
  const history = useHistory()
  const path = window.location.pathname
  const current_route = path.substring(path.lastIndexOf('/') + 1)
  const on_pricing = current_route === 'pricing'
  const on_blog = ['index'].includes(current_route)
  const on_help = ['faq', 'resources'].includes(current_route)
  const on_about_us = ['team', 'policies', 'terms-of-use-and-conditions'].includes(current_route)
  const on_contact_us = current_route === 'contact-us'
  const on_faq = current_route === 'faq'
  const on_resources = current_route === 'resources'
  const on_team = current_route === 'team'
  const on_policies = current_route === 'policies'
  const on_terms_of_use_and_conditions = current_route === 'terms-of-use-and-conditions'
  const goToLink = (link) => {
    history.push(getLinkToPath(link))
  }
  const handleLinkClick = (link) => {
    closeMenu()
    goToLink(link)
  }
  const goToPricing = () => {
    handleLinkClick('pricing')
  }
  const goToBlogIndex = () => {
    handleLinkClick('blog/index')
  }
  const goToFaq = () => {
    handleLinkClick('help/faq')
  }
  const goToResources = () => {
    handleLinkClick('help/resources')
  }
  const goToTeam = () => {
    handleLinkClick('about-us/team')
  }
  const goToPolicies = () => {
    handleLinkClick('policies')
  }
  const goToTermsOfUseAndConditions = () => {
    handleLinkClick('terms-of-use-and-conditions')
  }
  const goToContactUs = () => {
    handleLinkClick('contact-us')
  }

  const openHelpMenu = () => {
    setSecondaryMenu('help')
  }
  const openAboutUsMenu = () => {
    setSecondaryMenu('about_us')
  }
  const openLanguageMenu = () => {
    setSecondaryMenu('language')
  }
  const changeToSpanish = () => {
    if (language === 'es') {
      closeMenu()
    } else {
      changeLanguage('es')
    }
  }
  const changeToEnglish = () => {
    if (language === 'en') {
      closeMenu()
    } else {
      changeLanguage('en')
    }
  }
  const show_blog_link = language === 'es'

  const header = i18n.t('header', { returnObjects: true })
  const {
    pricing, blog, help, about_us, contact_us, faq, resources, team, policies, terms_of_use_and_conditions
  } = header

  const GoToPrimaryMenuButton = () => {
    return (
      <MenuItem
        className={'mobile-menu__item'}
        onClick={goToMainMenu}
      >
        <ArrowBackIcon/>
      </MenuItem>
    )
  }
  const PrimaryMenu = () => {
    return (
      <React.Fragment>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={goToPricing}
          selected={on_pricing}
        >
          {pricing}
        </MenuItem>
        {
          show_blog_link &&
          <MenuItem
            className={'mobile-menu__item'}
            onClick={goToBlogIndex}
            selected={on_blog}
          >
            {blog}
          </MenuItem>
        }
        <MenuItem
          className={'mobile-menu__item'}
          onClick={openHelpMenu}
          selected={on_help}
        >
          {help}
        </MenuItem>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={openAboutUsMenu}
          selected={on_about_us}
        >
          {about_us}
        </MenuItem>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={goToContactUs}
          selected={on_contact_us}
        >
          {contact_us}
        </MenuItem>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={openLanguageMenu}
        >
          {i18n.t('header.language')}
        </MenuItem>
        <MenuItem>
          <ThemeModeToggle full_width={true} />
        </MenuItem>
      </React.Fragment>
    )
  }

  const HelpSecondaryMenu = () => {
    return (
      <React.Fragment>
        <GoToPrimaryMenuButton/>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={goToFaq}
          selected={on_faq}
        >
          {faq}
        </MenuItem>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={goToResources}
          selected={on_resources}
        >
          {resources}
        </MenuItem>
      </React.Fragment>
    )
  }
  const AboutUsSecondaryMenu = () => {
    return (
      <React.Fragment>
        <GoToPrimaryMenuButton/>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={goToTeam}
          selected={on_team}
        >
          {team}
        </MenuItem>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={goToPolicies}
          selected={on_policies}
        >
          {policies}
        </MenuItem>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={goToTermsOfUseAndConditions}
          selected={on_terms_of_use_and_conditions}
        >
          {terms_of_use_and_conditions}
        </MenuItem>
      </React.Fragment>
    )
  }
  const LanguageSecondaryMenu = () => {
    return (
      <React.Fragment>
        <GoToPrimaryMenuButton/>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={changeToEnglish}
          selected={language === 'en'}
        >
          {'English'}
        </MenuItem>
        <MenuItem
          className={'mobile-menu__item'}
          onClick={changeToSpanish}
          selected={language === 'es'}
        >
          {'Español'}
        </MenuItem>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'text.primary'
        }}
      >
        <IconButton
          id={'mobile-nav-bar__menu--toggle'}
          aria-controls="dropdown" aria-haspopup="true"
          onClick={openMobileMenu}
          color="inherit"
          sx={{ p: 0 }}
        >
          <MenuIcon sx={{ fontSize: '2.5rem' }}/>
        </IconButton>
      </Box>
      <Menu
        id={'mobile-menu'}
        anchorEl={anchor_element}
        open={Boolean(anchor_element)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
      >
        { !secondary_menu && <PrimaryMenu/> }
        { secondary_menu === 'help' && <HelpSecondaryMenu/> }
        { secondary_menu === 'about_us' && <AboutUsSecondaryMenu/> }
        { secondary_menu === 'language' && <LanguageSecondaryMenu/> }
      </Menu>
    </React.Fragment>
  )
}
