import React from 'react'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { external_links } from '../../../assets/links/external_links'
import { Icon } from '../../../components/icons/icon'

export const WhatsAppButton = () => {
  return (
    <button
      id='layout__whats-app--button'
      className='button mobile'
    >
      <a
        href={external_links.ecclesia.whats_app.link}
        target="_blank"
        rel="noreferrer"
      >
        <Icon icon={faWhatsapp} />
      </a>
    </button>
  )
}
