import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './i18n'
import { I18nextProvider } from 'react-i18next'

ReactDOM.render(
  <I18nextProvider>
    <Router>
      <App />
    </Router>
  </I18nextProvider>,
  document.getElementById('root')
)
